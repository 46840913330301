import React, { useState, useEffect, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { Retry } from "../components/retry/Retry";
import { Status } from "../data/types";
import { useQueryClient } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { dataContracts } from "../data/dataContracts";
import { ContractId } from "../data/models/CommonTypes";
import { useContractId } from "../hooks/useContractId";
import { useIsMountedRef } from "../hooks/useIsMounted";
import { ServerError } from "../network/API";
import { contractState, notesState } from "../state/contractState";
import { PermissionError } from "../components/permissionError/PermissionError";

export const ContractLoader: React.FunctionComponent = () => {
  const contractId = useContractId();
  const setContract = useSetRecoilState(contractState);
  const setNotes = useSetRecoilState(notesState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [missingPermission, setMissingPermission] = useState(false);
  const mounted = useIsMountedRef();
  const queryClient = useQueryClient();

  const load = useCallback(
    (contractId: ContractId) => {
      setStatus(Status.PENDING);
      dataContracts
        .loadContract(contractId)
        .then((contractResponse) => {
          queryClient.setQueryData(
            dataContracts.getContractsKey(contractId),
            contractResponse
          );

          setNotes({
            notes: contractResponse.contractData.notes,
          });
          setContract(contractResponse);
          setStatus(Status.SUCCESS);
        })
        .catch((error: ServerError<unknown>) => {
          if (error.status === 403) {
            setMissingPermission(true);
          }
          setStatus(Status.ERROR);
        });
    },
    [queryClient, setNotes, setContract]
  );

  useEffect(() => {
    load(contractId as ContractId);
  }, [load, contractId]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }
      if (!contractId) {
        return;
      }
      load(contractId);
    }, 300);
  }, [load, contractId, mounted]);

  return (
    <>
      <Retry retry={retry} status={status}>
        {missingPermission ? <PermissionError /> : <Outlet />}
      </Retry>
    </>
  );
};
