import { atom, selector } from "recoil";
import { LegalEntityType } from "../data/dataCompany";
import {
  Cas,
  CompanyRegistrationId,
  ContractId,
} from "../data/models/CommonTypes";
import {
  Contract,
  ContractStatus,
  ProductType,
  MainContractData,
  Notes,
} from "../data/models/ContractTypes";
import { Country } from "../i18n";

const defaultContractData: MainContractData = {
  cas: 0 as Cas,
  companyName: "",
  organizationNumber: "" as CompanyRegistrationId,
  legalEntityType: LegalEntityType.LIMITED,
  dateOfIncorporation: "",
  invoiceAddress: {},
};

export const contractState = atom<Contract>({
  key: "contract-state",
  default: {
    contractId: "" as ContractId,
    readOnly: true,
    status: ContractStatus.SALES_INPUT,
    productType: ProductType.BAMBORA_ONE,
    salesUser: "",
    contractData: defaultContractData,
    enabledFeatures: [],
    country: Country.SWEDEN,
    allowedTerminals: [],
    salesforceUrl: "",
  },
});

export const notesState = atom<Notes>({
  key: "notes-state",
  default: {
    notes: "",
  },
});

export const disabledState = selector({
  key: "disabled-state",
  get: ({ get }) => {
    const { status, readOnly } = get(contractState);
    return status !== ContractStatus.SALES_INPUT || readOnly;
  },
});
