import React, { useCallback } from "react";
import cx from "classnames";
import styles from "./Terminals.module.scss";
import {
  Contract,
  Store,
  TERMINAL_TYPE,
  TerminalType,
} from "../../../../../data/models/ContractTypes";
import { Buttons } from "./components/Buttons";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  contractState,
  disabledState,
} from "../../../../../state/contractState";
import { Settings } from "./Settings";
import { OverviewTerminal } from "../../../../../data/models/OverviewTerminal";
import { T } from "../../../../../components/translation/T";

export interface Props {
  store: Store;
  isSaving: boolean;
  saveStore: (store: Store) => void;
  terminalOptions: OverviewTerminal[];
  formRef: React.RefObject<HTMLFormElement>;
  terminalType: TerminalType;
  isError: boolean;
}

const DEFAULT_WIRELESS_MODEL_ID = 1059;
const DEFAULT_WIRELESS_ANDROID_MODEL_ID = 1070; // TODO, check AXIUM DX8000

export const terminalName = {
  [TerminalType.WIRELESS_INTEGRATED]: "wireless integrated",
  [TerminalType.WIRELESS_STANDALONE]: "wireless standalone",
  [TerminalType.WIRED_INTEGRATED]: "wired integrated",
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]: "wireless integrated Android",
  [TerminalType.WIRELESS_STANDALONE_ANDROID]: "wireless standalone Android",
  [TerminalType.WIRED_INTEGRATED_ANDROID]: "wired integrated Android",
};

function isAndroidTerminal(type: TerminalType): boolean {
  if (type === TerminalType.WIRED_INTEGRATED) {
    return false;
  }

  if (type === TerminalType.WIRELESS_INTEGRATED) {
    return false;
  }

  if (type === TerminalType.WIRELESS_STANDALONE) {
    return false;
  }

  return true;
}

// Stupid, stupid naming
const terminalMapping: Record<TerminalType, TERMINAL_TYPE> = {
  [TerminalType.WIRELESS_STANDALONE]: TERMINAL_TYPE.WIRELESS,
  [TerminalType.WIRELESS_INTEGRATED]: TERMINAL_TYPE.INTEGRATED,
  [TerminalType.WIRED_INTEGRATED]: TERMINAL_TYPE.WIRED,
  [TerminalType.WIRELESS_STANDALONE_ANDROID]: TERMINAL_TYPE.WIRELESS_ANDROID,
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]: TERMINAL_TYPE.INTEGRATED_ANDROID,
  [TerminalType.WIRED_INTEGRATED_ANDROID]: TERMINAL_TYPE.WIRED_ANDROID,
};

function isStandalone(terminalType: TerminalType) {
  return terminalType === TerminalType.WIRELESS_STANDALONE;
}

function isStandaloneAndroid(terminalType: TerminalType) {
  return terminalType === TerminalType.WIRELESS_STANDALONE_ANDROID;
}

export function contractAllowsForTerminalType(
  contract: Contract,
  terminalType: TerminalType
) {
  return contract.allowedTerminals.includes(terminalMapping[terminalType]);
}

export const Terminals: React.FunctionComponent<Props> = (props) => {
  const disabled = useRecoilValue(disabledState);
  const contract = useRecoilValue(contractState);
  const {
    store,
    saveStore,
    isError,
    terminalType,
    formRef,
    isSaving,
    terminalOptions,
  } = props;

  const addTerminal = useCallback(() => {
    let copy;
    if (store[terminalType]) {
      copy = {
        ...store,
        [terminalType]: {
          ...store[terminalType],
          terminals: (store[terminalType] || { terminals: 0 }).terminals + 1,
        },
      };
    } else {
      if (isStandalone(terminalType)) {
        copy = {
          ...store,
          [terminalType]: {
            terminals: 1,
            terminalModelId: DEFAULT_WIRELESS_MODEL_ID,
          },
        };
      } else if (isStandaloneAndroid(terminalType)) {
        copy = {
          ...store,
          [terminalType]: {
            terminals: 1,
            terminalModelId: DEFAULT_WIRELESS_ANDROID_MODEL_ID,
          },
        };
      } else {
        copy = {
          ...store,
          [terminalType]: {
            terminals: 1,
            terminalModelId: -1,
            terminalOptions: [],
          },
        };
      }
    }

    saveStore(copy);
  }, [store, saveStore, terminalType]);

  const removeTerminal = useCallback(() => {
    let copy;

    if (store[terminalType]?.terminals === 1) {
      copy = structuredClone(store);
      delete copy[terminalType];
    } else {
      copy = {
        ...store,
        [terminalType]: {
          ...store[terminalType],
          terminals: (store[terminalType] || { terminals: 1 }).terminals - 1,
        },
      };
    }

    saveStore(copy);
  }, [store, saveStore, terminalType]);

  if (isAndroidTerminal(terminalType)) {
  } else if (!contractAllowsForTerminalType(contract, terminalType)) {
    // TODO, hide for now, so not to confuse
    if (isAndroidTerminal(terminalType)) {
      return null;
    }

    return (
      <p>
        <T>This price plan does not allow for terminals of type</T>{" "}
        <b>{terminalName[terminalType]}</b> 🤔
      </p>
    );
  }

  return (
    <div
      className={cx(styles.terminal, {
        [styles.hasTerminals]: (store[terminalType]?.terminals ?? 0) > 0,
        [styles.background]: terminalType !== TerminalType.WIRELESS_STANDALONE,
      })}
    >
      <Buttons
        onAdd={addTerminal}
        onRemove={removeTerminal}
        disabled={disabled}
        value={store[terminalType]?.terminals ?? 0}
        terminalType={terminalType}
      >
        <Trans>
          Select number of <strong>{terminalName[terminalType]}</strong>{" "}
          terminals
        </Trans>
      </Buttons>

      <Settings
        store={store}
        saveStore={saveStore}
        terminalOptions={terminalOptions}
        isSaving={isSaving}
        formRef={formRef}
        terminalType={terminalType}
        isError={isError}
      />
    </div>
  );
};
