import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { API } from "./network/API";
import LanguageDetector from "i18next-browser-languagedetector";

const SENT_CACHE = new Set<string>();

export type TI = any;

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum Country {
  SWEDEN = "SE",
  DENMARK = "DK",
  NORWAY = "NO",
  FINLAND = "FI",
}

export enum Language {
  ENGLISH = "en",
  SWEDISH = "sv",
  DANISH = "da",
  NORWEGIAN = "nb",
  FINNISH = "fi",
}

export enum Currency {
  SWEDEN = "SEK",
  DENMARK = "DKK",
  NORWAY = "NOK",
  FINLAND = "EUR",
}

export const ACTIVATED_LANGUAGES: Language[] = [
  Language.ENGLISH,
  Language.SWEDISH,
  // Language.DANISH,
  // Language.NORWEGIAN,
  // Language.FINNISH,
];

export const CurrencyByCountry: Record<Country, Currency> = {
  [Country.SWEDEN]: Currency.SWEDEN,
  [Country.DENMARK]: Currency.DENMARK,
  [Country.NORWAY]: Currency.NORWAY,
  [Country.FINLAND]: Currency.FINLAND,
};

export enum TRANSLATION_NAMESPACE {
  SALES = "sales",
  MERCHANT = "merchant",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.SALES;

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  /**
   * Let's try this custom config to utilize caching of selected language
   * Should probably find a better solution for this
   */
  .use(
    new LanguageDetector(undefined, {
      order: ["localStorage"],
    })
  )
  .init({
    debug: false,
    // Since our translation keys are the translation in english, we should not need a fallback
    fallbackLng: Language.ENGLISH,
    ns: [DEFAULT_TRANSLATION_NAMESPACE, TRANSLATION_NAMESPACE.MCC],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    load: "languageOnly",
    missingKeyHandler: (lng, ns, key) => {
      const language = lng[0];

      if (language === Language.ENGLISH) {
        return;
      }

      if (!key) {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/translation/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: key,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export default i18n;
