import { QueryOptions } from "@tanstack/react-query";
import { data } from "./data";
import { ContractId } from "./models/CommonTypes";
import { BankAccount } from "./models/ContractTypes";
import { API } from "../network/API";

const BASE_PATH = "/api/sales";

export interface LocationSuggestionResult {
  text: string;
}

// Delete and change?
// POST /api/sale/{contractId}/bank/reject
// {
//   firstName: string,
//   lastName: string,
//   email: string,
//   phoneNumber,
// }

export const dataBank = {
  fetchBank: (contractId: ContractId): QueryOptions<BankAccount> => ({
    queryKey: dataBank.getBankAccountKey(contractId),
    queryFn: () => dataBank.getBankAccount(contractId),
  }),

  getBankAccount: (contractId: ContractId) =>
    data.get<BankAccount>(`${BASE_PATH}/${contractId}/bank`),

  getBankAccountKey: (contractId: ContractId) => {
    return ["bank-account", contractId];
  },

  rejectBankAccount: (contractId: ContractId) =>
    data.delete(`${BASE_PATH}/${contractId}/bank`),

  addBankAccount: (contractId: ContractId, bankAccount: BankAccount) =>
    data.post(`${BASE_PATH}/${contractId}/bank/confirm`, bankAccount),

  getBankAccountStatementUrl: (contractId: ContractId) =>
    API.getUrl(`${BASE_PATH}/${contractId}/bank/document`),

  notifyRejection: (contractId: ContractId) =>
    data.delete(`${BASE_PATH}/${contractId}/bank`),

  remindAccountHolder: (contractId: ContractId) =>
    data.post(`${BASE_PATH}/${contractId}/bank/remind`),
};
